import styled from "styled-components";
import { theme, TypeTheme } from "../../theme";
import { Container } from "../Container";
import { TypeElementProps } from "../types";

const InternalPage = styled(Container)<TypeElementProps & { theme: TypeTheme }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.space.m};
  margin: "0";
  min-height: calc(100% - 70px);
`;

export const Page = ({ children }: { children: React.ReactNode }) => {
  return <InternalPage>{children}</InternalPage>;
};

import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { Image } from "../Image";
import { Container } from "../Container";
import { TypeTheme } from "../../theme";

const zoomInAndTranslate = keyframes`
  0% {
    transform: scale(1.1) translate(0, 0);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: scale(1.15) translate(-1.2%, -1.1%);
  }
`;

const JumbotronWrapper = styled(Container)<{ theme: TypeTheme }>`
  position: relative;
  width: 100%;
  height: ${(props) => props.theme.space.xxxxl};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    height: ${(props) => props.theme.space.xxxl};
  }
`;

const JumbotronImage = styled(Image)<{ active: boolean; alt: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.active ? 1 : 0)};
  ${(props) =>
    props.active &&
    css`
      animation: ${zoomInAndTranslate} 12s infinite;
    `};
`;

const NavButton = styled.button<{ theme: TypeTheme }>`
  position: absolute;
  top: 50%;
  width: fit-content;
  padding: 0;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: ${(props) => props.theme.text.size.xl};
  color: white;
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: lightgray;
  }
`;

const CenterContentWrapper = styled(Container)<{ theme: TypeTheme }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  text-align: center;
  color: ${(props) => props.theme.text.color.tertiary};
  font-size: 1.5rem;
`;

const Overlay = styled(Container)<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.show ? "rgba(0, 0, 0, 0.4)" : "none")};
  transition: background 0.3s ease;
  z-index: 1;
`;

const PrevButton = styled(NavButton)<{ theme: TypeTheme }>`
  text-align: left;
  left: 1%;
`;

const NextButton = styled(NavButton)<{ theme: TypeTheme }>`
  text-align: right;
  right: 1%;
`;

type TypeImage = {
  path: string;
  alt?: string;
};

interface IJumbotron {
  images: TypeImage[];
  centerContent?: React.ReactNode;
}

export const Jumbotron = ({ images, centerContent }: IJumbotron) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const transitionDuration = 12000; // 7 seconds

  useEffect(() => {
    const cycleImages = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, transitionDuration);

    return () => clearInterval(cycleImages);
  }, [images.length, transitionDuration]);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <JumbotronWrapper>
      {/* Dim overlay when centerContent is provided */}
      <Overlay show={!!centerContent} />
      {images.map((image, index) => (
        <JumbotronImage
          key={index}
          src={image.path}
          alt={image.alt || `Image ${index + 1}`}
          active={index === currentImageIndex}
          cover={true}
        />
      ))}

      {/* Render the center content if provided */}
      {centerContent && (
        <CenterContentWrapper>{centerContent}</CenterContentWrapper>
      )}
      <PrevButton onClick={goToPreviousImage}>‹</PrevButton>
      <NextButton onClick={goToNextImage}>›</NextButton>
    </JumbotronWrapper>
  );
};

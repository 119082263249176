import React, { useState } from "react";
import { Container } from "../Container";
import styled from "styled-components";
import { theme, TypeTheme } from "../../theme";
import { Text } from "../Text";
import { Button } from "../Button";
import { FaBars, FaTimes } from "react-icons/fa";

type TypeNav = {
  id: string;
  label: string;
  onClick: () => void;
  selected?: boolean;
};

interface INav {
  items: TypeNav[];
}

export const Nav = ({ items }: INav) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleItemClick = (onClick: () => void) => {
    onClick();
    setMenuOpen(false); // Close the menu on item click in mobile view
  };

  return (
    <NavContainer as="nav">
      <HamburgerButton onClick={toggleMenu}>
        {menuOpen ? (
          <ThinFaTimes color={theme.color.neutral.lavendar} size={25} />
        ) : (
          <ThinFaBars color={theme.color.neutral.lavendar} size={25} />
        )}
      </HamburgerButton>
      <MenuItems menuOpen={menuOpen}>
        {items.map((item) => (
          <MenuItem key={item.id} onClick={() => handleItemClick(item.onClick)}>
            <Button.Nav selected={item.selected}>
              <ResponsiveText>{item.label}</ResponsiveText>
            </Button.Nav>
          </MenuItem>
        ))}
      </MenuItems>
      <DesktopMenuItems>
        {items.map((item) => (
          <MenuItemDesktop key={item.id} onClick={item.onClick}>
            <Button.Nav selected={item.selected}>
              <Text.Small fontSize={theme.text.size.m}>{item.label}</Text.Small>
            </Button.Nav>
          </MenuItemDesktop>
        ))}
      </DesktopMenuItems>
    </NavContainer>
  );
};

// Custom styled thin versions of FaBars and FaTimes icons
const ThinFaBars = styled(FaBars)`
  stroke-width: 0.1; /* Thinner lines for hamburger icon */
`;

const ThinFaTimes = styled(FaTimes)`
  stroke-width: 0.1; /* Thinner lines for 'X' icon */
`;

const NavContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${theme.breakpoint.s}) {
    position: relative;
  }
`;

const HamburgerButton = styled.button`
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;

  @media (max-width: ${theme.breakpoint.s}) {
    display: block;
    position: absolute;
    right: 20px;
    top: 15px;
  }
`;

const MenuItems = styled.ul<{ menuOpen: boolean }>`
  display: none;

  @media (max-width: ${theme.breakpoint.s}) {
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.space.m};
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9); /* Solid background for mobile menu */
    z-index: 999;
    height: ${({ menuOpen }) =>
      menuOpen ? "100vh" : "0"}; /* Animate height */
    transition: height 0.4s ease, opacity 0.4s ease;
    opacity: ${({ menuOpen }) => (menuOpen ? 1 : 0)};
  }
`;

const MenuItem = styled(Container)`
  list-style: none;
  margin: 20px 0;
`;

const DesktopMenuItems = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${theme.breakpoint.s}) {
    display: none;
  }
`;

const MenuItemDesktop = styled(Container)`
  list-style: none;
  margin: 0 ${theme.space.m}; /* Space between nav items */
`;

const ResponsiveText = styled(Text.Small)`
  font-size: ${theme.text.size.m};

  @media (max-width: ${theme.breakpoint.s}) {
    font-size: ${theme.text.size.l};
  }
`;

import React from "react";
import { Container } from "../Container";
import { Image } from "../Image";
import { theme, TypeTheme } from "../../theme";
import styled from "styled-components";
import { Text } from "../Text";

interface IFooter {
  image: string;
  homeClick?: () => void;
}

const FooterContainer = styled(Container)<{ theme: TypeTheme }>`
  border-bottom: 15px solid ${(props) => props.theme.color.neutral.orange};
`;

const LogoImage = styled(Image)<{ theme: TypeTheme }>`
  width: ${(props) => props.theme.space.xl};
  height: ${(props) => props.theme.space.l};
`;

const FinePrint = styled(Text.Headline)<{ theme: TypeTheme }>`
  font-size: ${(props) => props.theme.text.size.s};
  color: ${(props) => props.theme.color.dark.lavendar};
`;

export const Footer = ({ image, homeClick }: IFooter) => {
  return (
    <FooterContainer
      height={70}
      width="100%"
      display="flex"
      pl={theme.space.m}
      justifyContent="center"
      alignItems="center"
    >
      <Container mr="auto">
        <LogoImage src={image} height={50} onClick={homeClick} />
      </Container>
      <Container>
        <FinePrint mx={10} mr={120}>
          500 Valley Forge Rd, Hillsborough, NC 27278 tel: 919-241-4212
        </FinePrint>
      </Container>
    </FooterContainer>
  );
};

import { createContext } from "react";
import { type TypeContact } from "../components/types";

export const ContactFormContext = createContext<{
  handleContactSubmit: (() => null) | ((data: TypeContact) => void);
  isSent: boolean;
}>({
  handleContactSubmit: () => null,
  isSent: false,
});

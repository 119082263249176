import React from "react";
import styled, { keyframes } from "styled-components";

interface IOverlay {
  children: React.ReactNode;
  onClose?: () => void;
}

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Slightly dimmed background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`;

const ContentWrapper = styled.div`
  z-index: 10000;
  animation: ${fadeIn} 0.5s ease-in-out; /* Apply fade-in animation */
`;

export const Overlay: React.FC<IOverlay> = ({ children, onClose }) => {
  return (
    <OverlayContainer onClick={onClose}>
      <ContentWrapper onClick={(e) => e.stopPropagation()}>
        {children}
      </ContentWrapper>
    </OverlayContainer>
  );
};

export default Overlay;

import { Container } from "../../components/Container";
import { EventCard } from "../../components/EventCard";
import { Page } from "../../components/Page";
import { theme } from "../../theme";

export const Events = () => {
  const handleEventSubmit = (id: string) => {
    console.log("id", id);
  };

  return (
    <Page>
      <Container
        p={theme.space.xxxl}
        display="grid"
        gap={40}
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content max-content max-content"
        justifyContent="center"
      >
        <EventCard
          id="medicinal_10202024"
          title="Medicinal Tea Class"
          description="We will be exploring all the amazing ways kids can heal with the power of plants. Learn how to make the right teas for the right situations and how tea can elevate kids emotionally in times of need. This is a great place for early adopters to come in and learn the ways of the force. Do it the way you like it always and forever..."
          icon="flower_1"
          date="Saturday, 10/20/2024"
          onSubmit={handleEventSubmit}
        />

        <EventCard
          id="farming_11012024"
          title="Farming Herbs"
          description="Discover the art of growing your own herbs in our hands-on herb farming class! Whether you're a gardening novice or an experienced green thumb, this course will teach you everything you need to know—from selecting the perfect herbs for your climate to cultivating them for the freshest flavors and best medicinal properties. Learn sustainable farming techniques, harvest tips, and even how to use your homegrown herbs for cooking, teas, and natural remedies. Join us and unlock the power of herbal farming—spaces are limited, so sign up today to start your herbal journey!"
          icon="cuppy"
          date="Wednesday, 11/1/2024"
          onSubmit={handleEventSubmit}
        />

        <EventCard
          id="grow_11202024"
          title="Grow Together"
          description="Turn gardening into a family adventure with our 'Grow Together' class, designed specifically for parents and kids! This fun and interactive course teaches the basics of planting, nurturing, and harvesting plants while building lasting memories with your little ones. Discover easy-to-grow veggies, herbs, and flowers perfect for small hands, and learn techniques that make gardening an exciting learning experience for children of all ages. Whether you're new to gardening or looking to pass on your love of nature, this class is the perfect way to bond, educate, and grow together. Sign up now—let's plant the seeds for a lifetime of growth!"
          icon="flower_2"
          date="Sunday, 11/20/2024"
          onSubmit={handleEventSubmit}
        />
      </Container>
    </Page>
  );
};

import styled from "styled-components";
import { theme, type TypeTheme } from "../../theme";
import { Button } from "../Button";
import { Card } from "../Card";
import { Image } from "../Image";
import { Text } from "../Text";
import { Container } from "../Container";
import { useCallback } from "react";

interface IEventCard {
  id: string;
  title: string;
  description: string;
  date: string;
  icon: "flower_1" | "flower_2" | "cuppy" | "magic_cuppy";
  onSubmit?: (id: string) => void;
}

const EnrollButton = styled(Button)<{ theme: TypeTheme }>`
  margin-left: auto;
`;

const EventHeadline = styled(Text.Headline)<{ theme: TypeTheme }>`
  font-size: ${(props) => props.theme.text.size.xl};
  margin: 20px 0;

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    font-size: ${(props) => props.theme.text.size.l};
    margin: 10px 0;
  }
`;

export const EventCard = ({
  id,
  title,
  icon,
  date,
  description,
  onSubmit,
}: IEventCard) => {
  const handleSubmit = useCallback(() => {
    onSubmit?.(id);
  }, []);
  return (
    <Card size="large">
      <Card.Header>
        <Image
          src={`/assets/images/icons/${icon}.png`}
          width={40}
          height={70}
          mr={20}
        />
        <EventHeadline>{title}</EventHeadline>
      </Card.Header>
      <Card.Body size="large">
        <Text.Headline my={20} fontSize={theme.text.size.l}>
          {date}
        </Text.Headline>
        <Text.Small mb={20}>{description}</Text.Small>
        <Container ml="auto">
          <EnrollButton variant="primary" onClick={handleSubmit}>
            <Text.Small fontSize={theme.text.size.m}>Enroll</Text.Small>
          </EnrollButton>
        </Container>
      </Card.Body>
    </Card>
  );
};

import styled from "styled-components";
import { Container } from "../../components/Container";
import { Image } from "../../components/Image";
import { Jumbotron } from "../../components/Jumbotron";
import { Page } from "../../components/Page";
import { Text } from "../../components/Text";
import { theme, type TypeTheme } from "../../theme";

const MissionStatementHeadline = styled(Text.Headline)<{ theme: TypeTheme }>`
  font-size: ${(props) => props.theme.text.size.xl};
  color: ${(props) => props.theme.color.dark.lavendar};

  @media (max-width: ${theme.breakpoint.s}) {
    font-size: ${theme.text.size.xl};
  }
`;

const MissionStatementText = styled(Text.Small)<{ theme: TypeTheme }>`
  font-size: ${(props) => props.theme.text.size.m};

  @media (max-width: ${theme.breakpoint.s}) {
    font-size: ${theme.text.size.m};
  }
`;

const MissionContainer = styled(Container)`
  width: 50%;
  text-align: center;
  margin: 0 auto;

  @media (max-width: ${theme.breakpoint.s}) {
    width: 100%;
  }
`;

const MissonIcon = styled(Image)`
  width: ${theme.size.s};
  height: ${theme.size.s};

  @media (max-width: ${theme.breakpoint.s}) {
    width: ${theme.size.s};
    height: ${theme.size.s};
  }
`;

const CenterBadge = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${theme.breakpoint.s}) {
    width: 100%;
  }
`;

const CenterBadgeHeadline = styled(Text.Headline)`
  color: ${theme.color.light.white};
  text-shadow: 1px 3px 2px ${theme.color.dark.lavendar};

  @media (max-width: ${theme.breakpoint.s}) {
    font-size: ${theme.text.size.l};
  }
`;

const CenterBadgeTagline = styled(Text.Small)`
  color: ${theme.color.neutral.yellow};

  @media (max-width: ${theme.breakpoint.s}) {
    font-size: ${theme.text.size.s};
  }
`;

export const Home = () => {
  return (
    <Page>
      <Container
        display="grid"
        gridTemplateColumns="1fr"
        gridTemplateRows="max-content max-content"
        gap={50}
      >
        <Jumbotron
          images={[
            { path: "/assets/images/jumbotron/basket.jpg" },
            { path: "/assets/images/jumbotron/kid_flower_1.jpg" },
            { path: "/assets/images/jumbotron/kid_flower_2.jpg" },
            { path: "/assets/images/jumbotron/kid_flower_3.jpg" },
            { path: "/assets/images/jumbotron/kid_flower_4.jpg" },
            { path: "/assets/images/jumbotron/mom_kid_1.jpg" },
            { path: "/assets/images/jumbotron/suntea.jpg" },
          ]}
          centerContent={
            <CenterBadge>
              <CenterBadgeHeadline>Kid Clover</CenterBadgeHeadline>
              <CenterBadgeTagline>
                Connecting kids to the magic of plants
              </CenterBadgeTagline>
            </CenterBadge>
          }
        />
        <MissionContainer>
          <Container
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <MissonIcon mx={10} src="/assets/images/icons/flower_1.png" />
            <MissionStatementHeadline>Our Mission</MissionStatementHeadline>
            <MissonIcon mx={10} src="/assets/images/icons/flower_2.png" />
          </Container>
          <MissionStatementText>
            <Container as="p" my={10}>
              To nurture a love for plants, wellness, and creativity in children
              through our carefully crafted herbal tea blends. We believe in the
              magic of nature and the dreams of childhood, creating kid-friendly
              teas that are both delicious and nourishing.
            </Container>
            <Container as="p">
              Our commitment extends beyond just the cup—through our interactive
              classes for parents and children, we empower families to make
              their own tea blends and explore the wonders of the natural world.
              With a focus on community, education, and sustainability, we aim
              to build a shared space where families can connect, learn, and
              grow together, one sip at a time.
            </Container>
          </MissionStatementText>
        </MissionContainer>
      </Container>
    </Page>
  );
};

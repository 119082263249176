import React, { useCallback, useState } from "react";
import { Card } from "../Card";
import { Container } from "../Container";
import { Input } from "../Input";
import { Text } from "../Text";
import { FaTimes } from "react-icons/fa";
import styled from "styled-components";
import { theme, TypeTheme } from "../../theme";
import { IStyleProps, TypeContact, TypeElementProps } from "../types";
import { Button, type IButton } from "../Button";
import { Image } from "../Image";
import { Loader } from "../Loader";
import { TextArea } from "../TextArea";

interface IContactForm {
  onSubmit: (contact: TypeContact) => void;
  onClose?: () => void;
  isSent?: boolean;
}

const FormCard = styled(Card)<{ theme: TypeTheme } & IStyleProps>`
  background-color: ${(props) => props.theme.color.neutral.lavendar};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    width: 90%;
  }
`;

const FormContainer = styled(Container)<
  { theme: TypeTheme } & TypeElementProps
>`
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content max-content max-content;
`;

const LabelText = styled(Text.Small)<{ theme: TypeTheme }>`
  color: ${(props) => props.theme.color.dark.lavendar};
`;

const FormCardHeadlineText = styled(Text.Headline)<{ theme: TypeTheme }>`
  color: ${(props) => props.theme.color.light.white};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    font-size: ${(props) => props.theme.text.size.l};
  }
`;

const BoldText = styled(Text.Headline)`
  color: ${(props) => props.theme.color.light.white};
  font-size: ${(props) => props.theme.text.size.m};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    font-size: ${(props) => props.theme.text.size.s};
  }
`;

const SubmitButton = styled(Button)<{ theme: TypeTheme } & IButton>`
  opacity: ${(props) => (props.disabled ? "0" : "1")};
  transition: opacity 1s ease;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1000;
  margin-left: 10px;
  font-weight: 100;
  transform: scale(0.8);
`;

const FormHeader = ({ onClose }: Partial<IContactForm>) => (
  <Card.Header>
    <Container display="flex" alignItems="center">
      <Image
        src="/assets/images/icons/magic_cuppy.png"
        width={60}
        height={80}
        mr={10}
      />
      <FormCardHeadlineText>Lets Keep In Touch!</FormCardHeadlineText>
      {onClose && (
        <CloseButton onClick={onClose}>
          <FaTimes color={theme.color.light.lavendar} size={25} />
        </CloseButton>
      )}
    </Container>
  </Card.Header>
);

export const ContactForm = ({
  onSubmit,
  onClose,
  isSent = false,
}: IContactForm) => {
  const [contact, setContact] = useState<TypeContact | null>(null);
  const [isSubmitted, setIsubmitted] = useState<boolean>(false);

  const handleSubmit = useCallback(() => {
    if (contact) {
      onSubmit?.(contact);
      setIsubmitted(true);
    }
  }, [contact]);

  const createContactAttributeHandler =
    (attr: "firstName" | "lastName" | "email" | "comment") => (value: string) =>
      setContact((prevContact) => {
        return {
          ...prevContact,
          [attr]: value,
        };
      });

  return isSent ? (
    <FormCard size="medium">
      <FormHeader onClose={onClose} />
      <Card.Body>
        <BoldText>Thanks! Talk soon...</BoldText>
      </Card.Body>
    </FormCard>
  ) : (
    <FormCard size="medium">
      <Card.Header>
        <Container display="flex" alignItems="center">
          <Image
            src="/assets/images/icons/magic_cuppy.png"
            width={60}
            height={80}
            mr={10}
          />
          <FormCardHeadlineText>Lets Keep In Touch!</FormCardHeadlineText>
          {onClose && (
            <CloseButton onClick={onClose}>
              <FaTimes color={theme.color.light.lavendar} size={25} />
            </CloseButton>
          )}
        </Container>
      </Card.Header>
      {isSubmitted && !isSent ? (
        <Container
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Loader />
        </Container>
      ) : (
        <Card.Body>
          <FormContainer>
            <Input
              label={<LabelText>First Name</LabelText>}
              value=""
              onChange={createContactAttributeHandler("firstName")}
            />
            <Input
              label={<LabelText>Last Name</LabelText>}
              value=""
              onChange={createContactAttributeHandler("lastName")}
            />
            <Input
              label={<LabelText>Email</LabelText>}
              value=""
              onChange={createContactAttributeHandler("email")}
            />
            <TextArea
              label={<LabelText>Comment</LabelText>}
              value=""
              onChange={createContactAttributeHandler("comment")}
            />
            <Container display="flex" justifyContent="center">
              <SubmitButton
                variant="primary"
                onClick={handleSubmit}
                disabled={
                  !contact?.email || !contact?.firstName || !contact?.lastName
                }
              >
                <Text.Small>Submit</Text.Small>
              </SubmitButton>
            </Container>
          </FormContainer>
        </Card.Body>
      )}
    </FormCard>
  );
};

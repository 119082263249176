import React from "react";
import styled from "styled-components";
import { type IStyleProps } from "../types";
import { Container } from "../Container";

interface IImage extends IStyleProps {
  src: string;
  cover?: boolean;
  onClick?: () => void;
}

export const Image = styled(Container).attrs(() => ({ role: "img" }))<IImage>`
  background-image: url("${(props) => props.src}");
  background-repeat: no-repeat;
  background-size: ${(props) => (props.cover ? "cover" : "contain")};
  background-position: center;

  &:hover {
    cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
  }
`;

import React from "react";
import styled from "styled-components";
import { darken, lighten } from "polished";
import { type TypeTheme } from "../../theme";
import { type TypeVariant } from "../types";

export interface IButton {
  variant?: TypeVariant;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

const getBackgroundColor =
  (toggle: boolean = false) =>
  (props: { theme: TypeTheme } & IButton) => {
    if (props.disabled) {
      return props.theme.background.color.disabled;
    }
    switch (props.variant) {
      case "primary":
        return props.theme.background.color[toggle ? "secondary" : "primary"];

      case "secondary":
        return props.theme.background.color[toggle ? "primary" : "secondary"];
    }
  };

const getColor =
  (toggle: boolean = false) =>
  (props: { theme: TypeTheme } & IButton) => {
    if (props.disabled) {
      return props.theme.text.color.disabled;
    }
    switch (props.variant) {
      case "primary":
        return toggle
          ? darken(0.15, props.theme.text.color.primary)
          : props.theme.text.color.primary;

      case "primary":
        return toggle
          ? darken(0.15, props.theme.text.color.primary)
          : props.theme.text.color.primary;
    }
  };

const getBorderColor =
  (toggle: boolean = false) =>
  (props: { theme: TypeTheme } & IButton) => {
    if (props.disabled) {
      return props.theme.border.color.disabled;
    }
    return props.theme.border.color.secondary;
  };

const InternalButton = styled.button<{ theme: TypeTheme } & IButton>`
  width: ${(props) => props.theme.space.xxl};
  height: ${(props) => props.theme.space.l};
  border: 3px solid ${getBorderColor()};
  background-color: ${getBackgroundColor()};
  color: ${getColor()};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease, color 0.3s ease;
  font-family: Kobajeon, sans-serif;
  border-radius: ${(props) => props.theme.border.radius.xxl};
  letter-spacing: 4px;
  &:hover {
    color: ${getColor(true)};
    background-color: ${(props) =>
      lighten(0.15, getBackgroundColor()(props) ?? "")};
    border-width: 2px;
    border-color: ${getBorderColor(true)};
  }
  &:active {
    border-width: 3px;
  }
`;

const Button = ({ variant, onClick, children, disabled = false }: IButton) => {
  return (
    <InternalButton onClick={onClick} variant={variant} disabled={disabled}>
      {children}
    </InternalButton>
  );
};

const NavButton = styled.button<{ theme: TypeTheme; selected: boolean }>`
  position: relative;
  padding: ${(props) => props.theme.space.s} ${(props) => props.theme.space.m};
  font-family: Kobajeon, sans-serif;
  color: ${(props) => props.theme.text.color.tertiary};
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${(props) => (props.selected ? "100%" : "0")};
    height: 2px;
    background-color: ${(props) => props.theme.background.color.tertiary};
    transition: width 0.3s ease;
  }

  &:hover {
    color: ${(props) => props.theme.text.color.primary};
  }

  &:hover::after {
    width: 100%;
  }
`;

interface INavButton extends IButton {
  selected?: boolean;
}

Button.Nav = ({ onClick, children, selected = false }: INavButton) => {
  return (
    <NavButton selected={selected} onClick={onClick}>
      {children}
    </NavButton>
  );
};

export { Button };

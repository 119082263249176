import React from "react";
import { type TypeElementProps } from "../types";
import { Element, type TypeElementNames } from "../Element";

export const Container = (
  props: TypeElementProps & {
    as?: TypeElementNames;
    children?: React.ReactNode;
  } = {
    as: "div",
    children: null,
  }
) => {
  return (
    <Element {...props} as={props.as}>
      {props.children}
    </Element>
  );
};

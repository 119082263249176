import React from "react";
import styled from "styled-components";
import { Container } from "../Container";
import { type TypeTheme } from "../../theme";
import { type TypeSize, type TypeElementProps } from "../types";
import { Element, type TypeElementNames } from "../Element";

interface ICard {
  size?: TypeSize;
}

export const CardHeader = styled(Container)<
  { theme: TypeTheme } & TypeElementProps & ICard
>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.color.light.lavendar};
  padding: ${(props) => props.theme.space.s};
  margin: 0;
`;

export const CardBody = styled(Container)<
  { theme: TypeTheme } & TypeElementProps & ICard
>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: none;
  padding: ${(props) => props.theme.space.m};
  margin: 0;
`;

export const BaseCard = styled(Element)<
  { theme: TypeTheme } & TypeElementProps & ICard
>`
  padding: ${(props) => props.theme.space.s};
  color: ${(props) => props.theme.color.neutral.white};
  background-color: ${(props) => props.theme.color.neutral.lavendar};
  border-radius: ${(props) => props.theme.border.radius.l};
  min-width: ${(props) =>
    props.size === "large" ? props.theme.space.xxxl : props.theme.space.xxl};
  max-width: ${(props) =>
    props.size === "large" ? props.theme.space.xxxxl : props.theme.space.xxxl};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    min-width: ${(props) =>
      props.size === "large"
        ? props.theme.space.xxl
        : props.size === "medium"
        ? props.theme.space.xl
        : props.theme.space.l};
    max-width: ${(props) => props.theme.space.xxxl};
  }
`;

export const Card = (
  props: TypeElementProps & {
    as?: TypeElementNames;
    children?: React.ReactNode;
  } & ICard = {
    as: "div",
    children: null,
    size: "small",
  }
) => {
  return (
    <BaseCard {...props} as={props.as}>
      {props.children}
    </BaseCard>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;

import styled from "styled-components";
import { type IStyleProps } from "../types";
import React from "react";

const getNumericVal = (val?: number | string) => {
  if (typeof val === "number") {
    return `${val}px`;
  }

  return val;
};

export const Element = styled.div<IStyleProps>`
  display: ${(props) => props.display};
  color: ${(props) => props.color};
  position: ${(props) => props.position};
  top: ${(props) => getNumericVal(props.top)};
  bottom: ${(props) => getNumericVal(props.bottom)};
  left: ${(props) => getNumericVal(props.left)};
  right: ${(props) => getNumericVal(props.right)};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-direction: ${(props) => props.flexDirection};
  flex: ${(props) => props.flex};
  font-weight: ${(props) => getNumericVal(props.fontWeight)};
  font-size: ${(props) => getNumericVal(props.fontSize)};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-column-start: ${(props) => props.gridColumnStart};
  grid-column-end: ${(props) => props.gridColumnEnd};
  grid-row-start: ${(props) => props.gridRowStart};
  grid-row-end: ${(props) => props.gridRowEnd};
  grid-column: ${(props) => props.gridColumn};
  grid-row: ${(props) => props.gridRow};
  gap: ${(props) => getNumericVal(props.gap)};
  width: ${(props) => getNumericVal(props.width)};
  list-style: ${(props) => props.listStyle};
  height: ${(props) => getNumericVal(props.height)};
  margin: ${(props) => getNumericVal(props.m)};
  margin-top: ${(props) =>
    getNumericVal(props.mt) ?? getNumericVal(props.my) ?? "0"};
  margin-bottom: ${(props) =>
    getNumericVal(props.mb) ?? getNumericVal(props.my) ?? "0"};
  margin-left: ${(props) =>
    getNumericVal(props.ml) ?? getNumericVal(props.mx) ?? "0"};
  margin-right: ${(props) =>
    getNumericVal(props.mr) ?? getNumericVal(props.mx) ?? "0"};
  padding: ${(props) => getNumericVal(props.p)};
  padding-top: ${(props) =>
    getNumericVal(props.pt) ?? getNumericVal(props.py) ?? "0"};
  padding-bottom: ${(props) =>
    getNumericVal(props.pb) ?? getNumericVal(props.py) ?? "0"};
  padding-left: ${(props) =>
    getNumericVal(props.pl) ?? getNumericVal(props.px) ?? "0"};
  padding-right: ${(props) =>
    getNumericVal(props.pr) ?? getNumericVal(props.px) ?? "0"};
  border: ${(props) => props.border};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => getNumericVal(props.borderRadius)};
  background-color: ${(props) => props.backgroundColor};
  transform: ${(props) => props.transform};
  overflow: ${(props) => props.overflow};
  overflow-y: ${(props) => props.overflowY};
  overflow-x: ${(props) => props.overflowX};
  text-shadow: ${(props) => props.textShadow};
  text-align: ${(props) => props.textAlign};
  z-index: ${(props) => props.zIndex};
`;

export type TypeElementNames =
  | "div"
  | "p"
  | "nav"
  | "header"
  | "aside"
  | "article"
  | "section"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "strong"
  | "em"
  | "ul"
  | "ol"
  | "li";

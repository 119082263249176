import { useContext } from "react";
import { ContactForm } from "../../components/ContactForm";
import { Page } from "../../components/Page";
import { ContactFormContext } from "../../context/contactform";
import styled from "styled-components";
import { Container } from "../../components/Container";
import { type TypeTheme } from "../../theme";
import { Image } from "../../components/Image";
import { Text } from "../../components/Text";

const GridContainer = styled(Container)<{ theme: TypeTheme }>`
  display: grid;
  gap: ${(props) => props.theme.space.m};
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  width: 80%;

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
  }
`;

const ScrollContainer = styled(Container)<{ theme: TypeTheme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100vh - 70px);

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    overflow-y: auto;
    height: auto;
  }
`;

const Year = styled.pre<{ theme: TypeTheme }>`
  display: inline;
`;

const Paragraph = styled.p<{ theme: TypeTheme }>`
  margin: ${(props) => props.theme.space.s};
`;

export const About = () => {
  const { handleContactSubmit, isSent } = useContext(ContactFormContext);
  return (
    <Page>
      <GridContainer>
        <ScrollContainer>
          <Container height={400}>
            <Image
              src="/assets/images/headshots/lesley.png"
              width={400}
              height={400}
            />
          </Container>
          <Text.Small>
            <Paragraph>
              Eleven years ago I placed my hands in the soil at Montalbano Farm
              in Sandwich, Illinois and harvested the first carrots (Daucus
              carota)…of my life. I wish someone had been there to photograph
              the expression on my face! I felt exuberance, delight and
              disbelief. What a miracle to behold!
            </Paragraph>
            <Paragraph>
              I did not grow up gardening. Nor did I have an understanding of
              exactly where food and medicine come from. But in that one clear
              moment I understood that we are here to connect with plants and to
              better understand how they are here to teach, lead and feed our
              spirits.
            </Paragraph>
            <Paragraph>
              If carrots laid the foundation for my new
              experimentation//mindset//curiosity then mint (Mentha peperita)
              opened my mind to see the world with new eyes.
            </Paragraph>
            <Paragraph>
              I began to read about culinary herbs and quickly realized that
              their benefits expanded further than tomato sauce and lentil soup.
              Rosemary (Rosmarinus officinalis) could be used to make shampoo!
              Thyme (Thymus vulgaris) for cough syrup! Oats (Avena sativa) for
              itchy skin! My spice rack quickly became my herbal pharmacy and
              the empowerment I felt crafting my own remedies was real and
              lasting.
            </Paragraph>
            <Paragraph>
              Wondrous curiosity continued as I learned about wild edibles and
              medicinal plants from many wise women. The Midwest Women's Herbal
              Conference, The Chestnut School of Herbal Medicine, Herbalista and
              Moonwise Herbs added layers to my herbal understanding while I
              simultaneously learned about growing veggies through concrete laid
              soil beds at Windy City Harvest in Chicago, Il.
            </Paragraph>

            <Paragraph>
              Education has always been the backbone of my career. I've taught
              in kindergarten classrooms and urban farms, kitchens and forests.
              All ages. Always with an eye towards lifelong learning,
              kind-heartedness, respect for all, including the green world.
            </Paragraph>

            <Paragraph>
              Now I'm teaching children to ask before plucking the leaves off of
              a tree. Explaining the importance of honoring knowledge keepers:
              our elders, both plants and people. Emboldening youngsters to care
              about sacred soil; who has tended it and that which grows in and
              underneath.
            </Paragraph>

            <Paragraph>
              In <Year>2003</Year> I had a breakthrough. I imagined the layered
              lives of my ancestors. And it was from their lived experiences I
              felt a connection to and through the soil. I'm unsure if my family
              were farmers but something in me connected deeply to the earth the
              day I took root of those carrots.
            </Paragraph>
          </Text.Small>
        </ScrollContainer>
        <Container display="flex" justifyContent="center">
          <ContactForm onSubmit={handleContactSubmit} isSent={isSent} />
        </Container>
      </GridContainer>
    </Page>
  );
};

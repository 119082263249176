import styled from "styled-components";
import { Container } from "../../components/Container";
import { Image } from "../../components/Image";
import { Page } from "../../components/Page";
import { type TypeTheme } from "../../theme";

const GridContainer = styled(Container)<{ theme: TypeTheme }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-templater-rows: repeat(20, 1fr);
  gap: 20px;
  width: 100%;
  height: 300vh;

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    grid-template-columns: 1fr;
    grid-templater-rows: repeat(9, 1fr);
    height: 500vh;
  }
`;

const GridImage = styled(Image)<{ theme: TypeTheme } & { index: number }>`
  grid-column-start: ${(props) => (props.index < 4 ? 1 : 2)};
  grid-column-end: ${(props) => (props.index < 4 ? 2 : 3)};
  grid-row-start: ${(props) =>
    props.index < 4 ? props.index * 5 + 1 : (props.index - 4) * 4 + 1};
  grid-row-end: ${(props) =>
    props.index < 4 ? (props.index + 1) * 5 + 1 : (props.index - 3) * 4 + 1};

  @media (max-width: ${(props) => props.theme.breakpoint.s}) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: ${(props) => props.index + 1};
    grid-row-end: ${(props) => props.index + 2};
  }
`;

const IMAGES = [
  "magenta_flower.png",
  "beauty.png",
  "iced_tea.png",
  "calendula.png",
  "sunset.png",
  "thebush.png",
  "desert.png",
  "tall_bush.png",
  "elderflower.png",
];

export const Gallery = () => {
  return (
    <Page>
      <GridContainer>
        {IMAGES.map((image, i) => (
          <GridImage
            index={i}
            src={`/assets/images/grid/${image}`}
            width="100%"
            height="100%"
            cover
          />
        ))}
      </GridContainer>
    </Page>
  );
};

import React from "react";
import styled from "styled-components";
import { IStyleProps, TypeElementProps } from "../types";
import { Element, type TypeElementNames } from "../Element";
import { TypeTheme } from "../../theme";

const BaseText = (
  { children, ...rest }: { children?: React.ReactNode } & IStyleProps = {
    children: null,
  }
) => (
  <Element as="p" {...rest}>
    {children}
  </Element>
);

const BaseTextHeadline = (
  { children, ...rest }: { children?: React.ReactNode } & IStyleProps = {
    children: null,
  }
) => (
  <Element as="h1" {...rest}>
    {children}
  </Element>
);

const Small = styled(BaseText)<TypeElementProps & { theme: TypeTheme }>`
  font-size: ${(props) => props.fontSize ?? props.theme.text.size.m};
  font-weight: ${(props) => props.fontWeight ?? props.fontWeight ?? 100};
  text-transform: uppercase;
`;

const Headline = styled(BaseTextHeadline)<
  TypeElementProps & { theme: TypeTheme }
>`
  display: block;
  font-size: ${(props) => props.fontSize ?? props.theme.text.size.xl};
  font-weight: ${(props) => props.fontWeight ?? props.fontWeight ?? 800};
  text-transform: uppercase;
`;

export const Text = {
  Small,
  Headline,
};

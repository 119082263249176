import React from "react";
import { FaSpinner } from "react-icons/fa";
import styled, { keyframes } from "styled-components";
import { type TypeTheme } from "../../theme";

// Create a spinning animation
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled component for the spinner
const Spinner = styled(FaSpinner)<{ theme: TypeTheme }>`
  animation: ${spin} 1s linear infinite;
  font-size: 2rem; /* Adjust size as needed */
  color: ${(props) => props.theme.text.color.secondary};
  margin: ${(props) => props.theme.space.s};
`;

// LoadingSpinner component
export const Loader = () => {
  return <Spinner />;
};

import React, { useCallback, useEffect, useState } from "react";
import { type IStyleProps } from "../types";
import styled from "styled-components";
import { TypeTheme } from "../../theme";
import { Element } from "../Element";
import { Container } from "../Container";
import { Text } from "../Text";

interface IInput extends IStyleProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  label?: string | React.ReactNode;
}

const InternalInput = styled(Element)<{ theme: TypeTheme } & IInput>`
  outline: none;
  border: none;
  min-width: ${(props) => props.theme.size.s};
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.color.light.white};
  border-radius: ${(props) => props.theme.border.radius.xxl};
  font-family: PumpkinPieLattesBold, sans-serif;
  font-size: ${(props) => props.theme.text.size.m};
  padding: ${(props) => props.theme.space.s};
  text-transform: none;
`;

const Label = styled(Text.Small)<{ theme: TypeTheme } & IStyleProps>`
  font-size: ${(props) => props.theme.text.size.s};
  color: ${(props) => props.theme.color.light.lavendar};
  margin-bottom: ${(props) => props.theme.space.s};
  padding-left: 0;
`;

export const Input = (props: IInput) => {
  const { onChange, label, ...rest } = props;
  const [internalValue, setInternalValue] = useState<string>(props.value);

  useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  const handleChange: React.FormEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setInternalValue(e.currentTarget.value);
      onChange(e.currentTarget.value);
    },
    []
  );

  return (
    <Container
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
    >
      <Label>{props.label}</Label>
      <InternalInput
        as="input"
        {...rest}
        value={internalValue}
        onInput={handleChange}
      />
    </Container>
  );
};

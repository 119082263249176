import React from "react";
import { Container } from "../Container";
import { Image } from "../Image";
import { theme, TypeTheme } from "../../theme";
import styled from "styled-components";

interface IHeader {
  Nav?: React.ReactNode;
  image: string;
  homeClick?: () => void;
}

const NavContainer = styled(Container)`
  display: flex;
  align-items: center;

  @media (max-width: ${theme.breakpoint.s}) {
    display: block;
  }
`;

const LogoImage = styled(Image)<{ theme: TypeTheme }>`
  width: ${(props) => props.theme.space.l};
  height: ${(props) => props.theme.space.l};
`;

export const Header = ({ Nav, image, homeClick }: IHeader) => {
  return (
    <Container
      height={70}
      width="100%"
      display="flex"
      pl={theme.space.m}
      justifyContent="space-between"
      alignItems="center"
      position="sticky"
      top={0}
      zIndex={3}
    >
      <Container flex={8}>
        <LogoImage src={image} height={50} onClick={homeClick} />
      </Container>
      <NavContainer flex={1} height="100%" py={theme.space.s}>
        {Nav}
      </NavContainer>
    </Container>
  );
};
